import React from 'react';
import * as propTypes from 'lib/prop_types';

/* eslint-disable max-len */
function FAQs({ className = '' }) {
  return (
    <svg className={`FAQs ${className}`} fill="none" height="48" viewBox="0 0 58 48" width="58" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M44.7627 10.8693V18.0923H33.2562C32.669 18.0811 32.0841 18.1669 31.5256 18.3462C30.5733 18.6376 29.7066 19.1523 28.9998 19.8462C28.3442 20.476 27.8241 21.2297 27.4705 22.0624C27.1168 22.8951 26.937 23.7895 26.9417 24.6923V32.9077H12.9095L1.05221 44.2385V10.8693C1.03765 9.37502 1.3768 7.89806 2.04267 6.55595C2.70853 5.21383 3.68272 4.04363 4.88769 3.13849C6.3899 1.9994 8.23186 1.38274 10.1264 1.38465H35.6885C37.9604 1.38986 40.1411 2.26713 41.7691 3.8308C42.726 4.74215 43.4849 5.83617 43.9996 7.04622C44.5142 8.25626 44.7739 9.55702 44.7627 10.8693V10.8693Z" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M57.0644 24.6V47.5385L48.9257 39.6923H33.2563C32.4234 39.6904 31.6001 39.517 30.839 39.1832C30.0779 38.8494 29.3958 38.3626 28.8362 37.7539C27.6501 36.5036 26.9969 34.851 27.012 33.1385V24.6C27.0105 23.7176 27.1854 22.8436 27.5265 22.0279C27.8676 21.2122 28.3682 20.4708 28.9999 19.8462C29.6628 19.2101 30.462 18.729 31.3386 18.4385C31.8971 18.2592 32.482 18.1734 33.0692 18.1846H50.633C51.9351 18.1794 53.2022 18.6015 54.2346 19.3846C55.089 19.9729 55.7905 20.7518 56.2823 21.658C56.774 22.5643 57.042 23.5723 57.0644 24.6Z" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M13.354 18.5308C14.8652 18.5308 16.0903 17.2496 16.0903 15.6692C16.0903 14.0888 14.8652 12.8077 13.354 12.8077C11.8428 12.8077 10.6177 14.0888 10.6177 15.6692C10.6177 17.2496 11.8428 18.5308 13.354 18.5308Z" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M22.9194 18.5308C24.4306 18.5308 25.6557 17.2496 25.6557 15.6692C25.6557 14.0888 24.4306 12.8077 22.9194 12.8077C21.4082 12.8077 20.1831 14.0888 20.1831 15.6692C20.1831 17.2496 21.4082 18.5308 22.9194 18.5308Z" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M35.1977 15.6691C35.2018 16.1437 35.089 16.6122 34.869 17.0343C34.649 17.4564 34.3284 17.8194 33.9348 18.0922H33.2566C32.6694 18.0809 32.0844 18.1667 31.5259 18.3461C30.9912 18.128 30.5355 17.7559 30.2184 17.2783C29.9013 16.8008 29.7376 16.24 29.7485 15.6691C29.7134 15.2951 29.7577 14.918 29.8786 14.5619C29.9996 14.2058 30.1945 13.8785 30.4509 13.6009C30.7073 13.3233 31.0196 13.1016 31.3678 12.9499C31.716 12.7982 32.0925 12.7198 32.4731 12.7198C32.8537 12.7198 33.2302 12.7982 33.5784 12.9499C33.9266 13.1016 34.2389 13.3233 34.4953 13.6009C34.7517 13.8785 34.9467 14.2058 35.0676 14.5619C35.1885 14.918 35.2329 15.2951 35.1977 15.6691V15.6691Z" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M39.314 26.3768C39.346 25.8499 39.5339 25.3437 39.8543 24.921C40.1748 24.4983 40.6139 24.1776 41.1171 23.9985C41.6204 23.8195 42.1658 23.7899 42.686 23.9135C43.2061 24.0371 43.6781 24.3084 44.0436 24.6939C44.4091 25.0794 44.652 25.5622 44.7423 26.0825C44.8327 26.6028 44.7665 27.1379 44.5519 27.6215C44.3373 28.105 43.9837 28.5159 43.5349 28.8033C43.0862 29.0907 42.5619 29.2419 42.0269 29.2384" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M40.6709 34.7538V34.9615" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
        <path d="M42.0267 29.2385C41.8424 29.2445 41.6611 29.2862 41.4931 29.3614C41.3252 29.4365 41.1739 29.5436 41.0479 29.6765C40.9219 29.8094 40.8237 29.9654 40.7589 30.1358C40.6941 30.3062 40.664 30.4874 40.6702 30.6693V31.5924" stroke="#158D71" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="48" width="58" />
        </clipPath>
      </defs>
    </svg>
  )
}

FAQs.propTypes = {
  className: propTypes.string,
};

export default FAQs;
