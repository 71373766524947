import React from 'react';
import classNames from 'classnames';

import {
  CALENDAR_PROVIDER_GOOGLE,
  CALENDAR_PROVIDER_MICROSOFT,
  CALENDAR_PROVIDER_OFFICE365,
} from 'lib/generated_constants/scheduling';
import * as propTypes from 'lib/prop_types';
import { Microsoft, Outlook } from 'common/icons/brands';
import { useIsNylasMicrosoftEnabled } from 'hooks/use_is_nylas_microsoft_enabled';

import styles from './connect_calendar_modal.module.scss';

function ConnectCalendarButtons({ className, onConnect }) {
  const handleSyncGoogle = () => {
    onConnect(CALENDAR_PROVIDER_GOOGLE);
  };

  const useMicrosoftCalendar = useIsNylasMicrosoftEnabled();

  const handleSyncOffice = () => {
    if (useMicrosoftCalendar) {
      onConnect(CALENDAR_PROVIDER_MICROSOFT);
      return;
    }
    onConnect(CALENDAR_PROVIDER_OFFICE365);
  };

  return (
    <>
      <button
        className={classNames(
          'btn',
          className,
          styles.ConnectButtonGoogle,
        )}
        type="button"
        onClick={handleSyncGoogle}
      >
        <i className="icon-left fab fa-google" />
        Connect with Google
      </button>
      <button
        className={classNames(
          'btn',
          className,
          styles.ConnectButtonOffice365,
        )}
        type="button"
        onClick={handleSyncOffice}
      >
        { useMicrosoftCalendar ? (
          <>
            <Microsoft className={styles.MicrosoftIcon} />
            Connect with Microsoft
          </>
        ) : (
          <>
            <Outlook className="icon-left" />
            Connect with Office 365
          </>
        )}
      </button>
    </>
  );
}

ConnectCalendarButtons.propTypes = {
  className: propTypes.string,
  onConnect: propTypes.func.isRequired,
};

export default ConnectCalendarButtons;
