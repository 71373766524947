import React from 'react';
import classNames from 'classnames';
import * as propTypes from 'lib/prop_types';
import { ModalBody, ModalHeader } from '@user-interviews/ui-design-system';

import { trackingEvents } from 'lib/analytics';
import { TrackedModal } from 'common/modals';
import ConnectCalendarButtons from './connect_calendar_buttons';

import styles from './connect_calendar_modal.module.scss';

function ConnectCalendarModal(props) {
  return (
    <TrackedModal
      event={trackingEvents.CONNECT_CALENDAR_MODAL_OPENED}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
    >
      <ModalHeader
        title="Select the calendar to connect"
        titleId="connect-calendar-modal"
        onRequestClose={props.onRequestClose}
      />
      <ModalBody>
        <p>
          Easily see your availability while selecting times on our site and
          automatically have calendar events created for your confirmed sessions.
        </p>
        <ConnectCalendarButtons
          className={classNames(
            'btn-lg',
            styles.ConnectButton,
          )}
          onConnect={props.onConnect}
        />
        <p>
          Note: Research time slots can only be edited in our app. Editing events
          from User Interviews in your own calendar software will break stuff and make
          everyone sad.
        </p>
      </ModalBody>
    </TrackedModal>
  )
}

ConnectCalendarModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onConnect: propTypes.func.isRequired,
  onRequestClose: propTypes.func,
};

export default ConnectCalendarModal;
