import React from 'react';

import { Button, Alert, MessageTypes } from '@user-interviews/ui-design-system';

import * as routes from 'lib/routes';

type UpdateCalendarAlertProps = {
  showDisconnect?: boolean,
}
export function UpdateCalendarAlert({ showDisconnect = false }: UpdateCalendarAlertProps) {
  const handleInitiateDisconnect = () => {
    window.location.href = routes.initiate_disconnect_account_calendar_path({
      return_to: `${window.location}`,
    })
  }

  return (
    <Alert
      action={(showDisconnect && <Button variant="transparent" onClick={handleInitiateDisconnect}>Disconnect</Button>)}
      message="Please disconnect and reconnect your calendar to update the integration and access scheduling features."
      type={MessageTypes.WARNING}
    />
  )
}
