import React from 'react';
import * as propTypes from 'lib/prop_types';

function ConnectProviderButton({ ariaLabel = '', labelText = 'Continue', onClick = () => {} }) {
  return (
    <button
      aria-label={ariaLabel}
      className="Alert-info primary-action"
      type="button"
      onClick={onClick}
    >
      {labelText}
    </button>
  )
}

ConnectProviderButton.propTypes = {
  ariaLabel: propTypes.string,
  labelText: propTypes.string,
  onClick: propTypes.func,
};

export default ConnectProviderButton;
