import React, { useState } from 'react';
import * as propTypes from 'lib/prop_types';
import * as routes from 'lib/routes';

import { faExternalLinkAlt } from 'lib/font_awesome/solid';
import { providerNames } from 'lib/generated_constants/integrations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@user-interviews/ui-design-system';

import { DisconnectCalendarIntegrationModal } from 'components/disconnect_calendar_integration_modal';

import { connectedCalendarDetailsPropType } from './prop_types';

import styles from './connected_calendar_message.module.scss';

function AccountAvailabilityLink() {
  return (
    <div className={styles.AccountAvailabilityLink}>
      <a
        href={routes.current_account_availability_settings_path()}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon className="icon-left" icon={faExternalLinkAlt} />
        Update account availability and calendar settings
      </a>
    </div>
  )
}

function ConnectedCalendarMessage({
  connectedCalendarDetails,
  isReadonly = false,
  showAccountAvailabilityLink = false,
  showHeader = true,
  onDisconnect,
}) {
  const { email, provider, tokenInvalid } = connectedCalendarDetails;
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);

  if (isReadonly) {
    return (
      <p>
        Calendar currently connected to {email}
      </p>
    );
  }

  const isGoogleOrMicrosoft = [providerNames.GOOGLE, providerNames.MICROSOFT].includes(provider);

  const handleDisconnectOffice = () => {
    window.location = routes.initiate_disconnect_account_calendar_path({
      return_to: `${window.location}`,
    });
  };

  const handleDisconnect = async () => {
    if (isGoogleOrMicrosoft) {
      setIsOpen(true);
    } else {
      if (onDisconnect) await onDisconnect();
      handleDisconnectOffice();
    }
  };

  return (
    <div className={styles.ConnectedCalendarMessage}>
      {showHeader && <h4>My Calendar</h4>}

      {showAccountAvailabilityLink && <AccountAvailabilityLink />}

      <div className={styles.ConnectedCalendarMessage__contents}>
        {`${email}`}{tokenInvalid && ' -- invalid'}
        <Button
          type="button"
          variant="link"
          onClick={handleDisconnect}
        >
          Disconnect
        </Button>
      </div>

      {isGoogleOrMicrosoft && (
        <DisconnectCalendarIntegrationModal
          disabled={false}
          isOpen={isOpen}
          provider={provider}
          onClose={closeModal}
          onDisconnect={onDisconnect}
        />
      )}
    </div>
  );
}

ConnectedCalendarMessage.propTypes = {
  connectedCalendarDetails: connectedCalendarDetailsPropType.isRequired,
  isReadonly: propTypes.bool,
  showAccountAvailabilityLink: propTypes.bool,
  showHeader: propTypes.bool,
  onDisconnect: propTypes.func,
};

export default ConnectedCalendarMessage;
