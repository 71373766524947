import React from 'react';
import * as propTypes from 'lib/prop_types';

import { mentionTypes } from 'lib/generated_constants/comments';

export function Body({ comment, commenters }) {
  return (
    <div className="Comment__body">
      {comment.blocks.map(
        (block, i) => (block.type === mentionTypes.COMMENTER ?
          (
          // eslint-disable-next-line react/no-array-index-key
            <span className="Comment__mention" key={i}>
              @{commenters[block.value] ? commenters[block.value].fullName : block.value}
            </span>
          ) :
          block),
      )}
    </div>
  )
}

Body.propTypes = {
  comment: propTypes.shape({
    blocks: propTypes.arrayOf(
      propTypes.oneOfType([
        propTypes.string,
        propTypes.shape({
          type: propTypes.string,
          value: propTypes.string,
        }),
      ]),
    ).isRequired,
  }).isRequired,
  commenters: propTypes.objectOf(propTypes.shape({ fullName: propTypes.string })),
};

Body.defaultProps = {
  commenters: {},
};
