import { createElement } from 'react';
import classNames from 'classnames';
import * as propTypes from 'lib/prop_types';

import './card.scss';

function Card({
  children,
  className,
  elementType = 'section',
  ...props
}) {
  return createElement(
    elementType,
    { ...props, className: classNames('card ui-card', className) },
    children,
  )
}

Card.propTypes = {
  className: propTypes.string,
  elementType: propTypes.oneOfType([propTypes.func, propTypes.string]),
};

export default Card;
